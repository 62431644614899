import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AuthService } from '@app/auth';
import { CategoryService, ClientService, FacetService, LookService, SelectionsService, UserService } from '@app/core';
import { Category, Client, Facet, Item, Look, TabSelections, User } from '@app/core/store';
import { NotificationObject, NotificationType } from '@app/shared/notification/notificationObject';

import { Subscription } from 'rxjs';

@Component({
  selector: 'component-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.sass'],
})
export class ManageComponent implements OnInit, OnDestroy {
  getItemLooksSubscription$: Subscription;
  looksSubscription$: Subscription;
  // Filters values
  categories: Category[];
  clients: Client[];
  selectedClient: Client;
  filters: Facet[] = [];
  page = 1;
  searchLookUid = '';
  selections: { categories: Category[]; facets: Facet[]; users?: Facet[] };
  tabFacets: Facet[];
  lookUsers: Facet[];

  // Look Container values
  looks: Look[] = [];
  disableScroll = false;

  // New variable for rebuilt deep-linking
  private openedLook: Look = null;

  // Modal values
  user: User;

  // Loader values
  showLoader = true; // Property to hide or display the loader in the items.

  // Notification
  notification = new NotificationObject();
  notificationType = NotificationType;

  constructor(
    private auth: AuthService,
    private categoryService: CategoryService,
    private clientService: ClientService,
    private facetService: FacetService,
    private lookService: LookService,
    private route: ActivatedRoute,
    private router: Router,
    private selectionsService: SelectionsService,
    private userService: UserService,
  ) {
    // Check for deep-linked look in route data
    if (route.snapshot.firstChild) {
      const routeData: Data = route.snapshot.firstChild.data;

      // An invalid UID parameter will return null, otherwise assign returned look to the local 'openedLook' variable
      if (routeData.openedLook !== null) {
        this.openedLook = routeData.openedLook;
      } else {
        const uid = route.snapshot.firstChild.params['openedLookUid'];
        this.notification.display(this.notificationType.notice, 'Look ' + uid + ' was not found.');
      }
    }
  }

  ngOnInit(): void {
    this.clientService.getSelectedClient().subscribe(
      (client) => {
        if (this.openedLook && this.openedLook.clientUid !== client.uid) {
          this.notification.display(this.notificationType.notice, 'Look ' + this.openedLook.uid + ' does not belong to selected client.');
          this.openedLook = null;
        }
        this.loadTab(client);
      },
      (error) => {
        // Handle no selected client error by redirecting user to Select client screen
        console.error(error);
        this.router.navigate(['/select']);
      },
    );
  }

  loadTab(client: Client): void {
    this.selectedClient = client;
    this.clientService.setSelectedClient(this.selectedClient);

    this.categoryService.getClientCategories(this.selectedClient.uid, 'manage').subscribe((response) => {
      this.categories = response;
    });

    if (this.auth.getRole() == 'admin') {
      this.clientService.getAllClients().subscribe((clients) => {
        this.clients = clients;
      });
    } else {
      this.clientService.getUserClients().subscribe((clients) => {
        this.clients = clients;
      });
    }

    this.facetService.getManageFacets().subscribe((facets) => {
      this.tabFacets = facets;
    });

    this.facetService.getClientFilters('manage').subscribe((response) => {
      this.filters = response;
    });

    this.selectionsService.getSelections('manage').subscribe((response) => {
      this.selections = response.selections;
    });

    this.userService.getStoredUser().subscribe((response) => {
      this.user = response;
    });

    this.userService.getClientUsers(this.selectedClient.uid).subscribe((response) => {
      this.lookUsers = response;
    });

    if (this.openedLook !== null) {
      // If a deep-linked look is opened, display only that look
      this.looks = [this.openedLook];
      this.showLoader = false;
      this.disableScroll = false;
    } else {
      this.looksSubscription$ = this.lookService.getClientLooks().subscribe((response) => {
        this.looks = response;
        this.showLoader = false;
        this.disableScroll = false;
      });
    }
  }

  displayNotification(childNotification) {
    // Used for child component notifications
    this.notification.display(childNotification.type, childNotification.message);
  }

  onItemEdit(data: { item: Item; lookUid: string }): void {
    // Find set and item then reassign with updated item data
    const lookIndex = this.looks
      .map((look) => {
        return look.uid;
      })
      .indexOf(data.lookUid);
    const itemIndex = this.looks[lookIndex].items
      .map((item) => {
        return item.uid;
      })
      .indexOf(data.item.uid);
    this.looks[lookIndex].items[itemIndex] = data.item;
  }

  onGetItemLooks(): void {
    this.getItemLooksSubscription$ = this.lookService.getClientLooks().subscribe((response) => {
      this.looks = response;
    });
    this.getItemLooksSubscription$.unsubscribe();
  }

  onSelectClient(client: Client) {
    this.selectedClient = client;
  }

  onLookSearch(event): void {
    this.selections = event.selections;
    this.searchLookUid = event.query;
    this.loadLooks();
  }

  onClickFilters(event: TabSelections): void {
    // Reset page on filter click
    this.page = 1;
    this.selections = event.selections;
    this.selectionsService.storeSelections('manage', { selections: this.selections });
    this.loadLooks();
  }

  loadMoreLooks() {
    this.page += 1;
    this.loadLooks();
  }

  private loadLooks() {
    this.disableScroll = true;
    this.showLoader = true;
    this.lookService.filterLooks(this.selections, this.page, this.searchLookUid).subscribe(
      (response) => {
        this.showLoader = false;
        this.disableScroll = false;
        if ((this.page > 1 && response.length < 20 && !this.searchLookUid) || response.length < 20 * (this.page - 1)) {
          this.notification.display(this.notificationType.notice, 'No looks were found for the filter criteria.');
        } else {
          this.looks = response;
        }
      },
      (error) => {
        this.showLoader = false;
        this.disableScroll = false;
        console.error(error);
        this.notification.display(this.notificationType.error, 'Oops! Something went wrong. Please email us at support@findmine.com.');
      },
    );
  }

  ngOnDestroy() {
    if (this.looksSubscription$) {
      this.looksSubscription$.unsubscribe();
    }
  }
}
