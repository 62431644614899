import { inject, NgModule } from '@angular/core';
import { CanActivateFn, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

import { ClientSelectComponent } from './client-select/client-select.component';
import { CreateComponent } from './create/create.component';
import { LoginComponent } from './login/login.component';
import { ManageComponent } from './manage/manage.component';
import { SnapshotComponent } from './snapshot/snapshot.component';

import { AuthService } from './auth';
import { DevFlagService } from './core/dev-flag.service';
import { openItemResolver } from './core/open-item.resolver';
import { openLookResolver } from './core/open-look.resolver';
import { LooksV4Component } from './shared/looks-v4/looks-v4.component';
import { V4DebugComponent } from './shared/v4-debug/v4-debug.component';
import { WidgetsComponent } from './widgets/widgets.component';

const AdminGuard: CanActivateFn = () => {
  const auth = inject(AuthService);

  if (auth.getRole() === 'admin') {
    return true;
  }

  return false;
};

const v4Guard: CanActivateFn = () => {
  const devFlagService = inject(DevFlagService);

  if (devFlagService.isFlagEnabled('v4')) {
    return true;
  }

  return false;
};

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'select',
    component: ClientSelectComponent,
  },
  {
    path: 'create',
    canActivate: [AuthGuard],
    component: CreateComponent,
    children: [
      {
        path: 'item/:openedItemUid',
        component: CreateComponent,
        resolve: {
          openedItem: openItemResolver,
        },
      },
      {
        path: 'look/:openedLookUid',
        component: CreateComponent,
        resolve: {
          openedLook: openLookResolver,
        },
      },
    ],
  },
  {
    path: 'manage',
    canActivate: [AuthGuard],
    component: ManageComponent,
    children: [
      {
        path: 'look/:openedLookUid',
        component: ManageComponent,
        resolve: {
          openedLook: openLookResolver,
        },
      },
    ],
  },
  {
    path: 'snapshot',
    canActivate: [AuthGuard],
    component: SnapshotComponent,
  },
  {
    path: 'widgets',
    canActivate: [AuthGuard, AdminGuard],
    component: WidgetsComponent,
    children: [{ path: '**', component: WidgetsComponent }],
  },
  {
    path: 'looks',
    canActivate: [AuthGuard, v4Guard],
    component: LooksV4Component,
    children: [{ path: '**', component: LooksV4Component }],
  },
  {
    path: 'v4-debug',
    component: V4DebugComponent,
  },
  { path: '**', redirectTo: 'create' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
